<template>
  <div class="app">
    <el-dialog
      title="选择门店"
      :visible.sync="dialogSendVisible"
      width="40%"
      :before-close="handleSendClose"
    >
      <el-timeline>
        <tree-transfer
          :title="title"
          :from_data="fromData"
          :to_data="toData"
          :defaultProps="{ label: 'label' }"
          @addBtn="add"
          @removeBtn="remove"
          :mode="mode"
          height="540px"
          filter
          openAll
        >
        </tree-transfer>
      </el-timeline>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleSendClose">取 消</el-button>
        <el-button type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import treeTransfer from "el-tree-transfer"; // 引入
export default {
  components: {
    treeTransfer,
  },
  data() {
    return {
      mode: "transfer", // transfer addressList
      fromData: [
        {
          id: "1",
          pid: 0,
          label: "一级 1",
          children: [
            {
              id: "1-1",
              pid: "1",
              label: "二级 1-1",
              disabled: true,
              children: [],
            },
            {
              id: "1-2",
              pid: "1",
              label: "二级 1-2",
              children: [
                {
                  id: "1-2-1",
                  pid: "1-2",
                  children: [],
                  label: "二级 1-2-1",
                },
                {
                  id: "1-2-2",
                  pid: "1-2",
                  children: [],
                  label: "二级 1-2-2",
                },
              ],
            },
          ],
        },
        {
          id: "2",
          pid: 0,
          label: "广东省",
          children: [
            {
              id: "2-1",
              pid: "2",
              label: "广州市 2-1",
              children: [
                {
                  id: "2-1-1",
                  pid: "2-1",
                  children: [],
                  label: "天河区",
                },
                {
                  id: "2-1-2",
                  pid: "2-1",
                  children: [],
                  label: "白云区",
                },
              ],
            },
            {
              id: "2-2",
              pid: "2",
              label: "深圳市 2-2",
              children: [
                {
                  id: "2-2-1",
                  pid: "2-2",
                  children: [],
                  label: "南山区",
                },
                {
                  id: "2-2-2",
                  pid: "2-2",
                  children: [],
                  label: "福田区",
                },
              ],
            },
          ],
        },
      ],
      toData: [],
      dialogSendVisible: true,
    };
  },
  methods: {
    // 切换模式 现有树形穿梭框模式transfer 和通讯录模式addressList
    changeMode() {
      if (this.mode == "transfer") {
        this.mode = "addressList";
      } else {
        this.mode = "transfer";
      }
    },
    // 监听穿梭框组件添加
    add(fromData, toData, obj) {
      // 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的{keys,nodes,halfKeys,halfNodes}对象
      // 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表
      console.log("fromData:", fromData);
      console.log("toData:", toData);
      console.log("obj:", obj);
    },
    // 监听穿梭框组件移除
    remove(fromData, toData, obj) {
      // 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的{keys,nodes,halfKeys,halfNodes}对象
      // 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表
      console.log("fromData:", fromData);
      console.log("toData:", toData);
      console.log("obj:", obj);
    },

    handleSendClose() {
      this.dialogSendVisible = false;
      this.$emit("dialog", false);
    },
  },
};
</script>
