<template>
  <div class="app">
    <el-dialog
      title="选择门店"
      :visible.sync="dialogSendVisible"
      width="50%"
      :before-close="handleSendClose"
    >
      <el-timeline>
        <el-form :inline="true" :model="where" class="demo-form-inline">
          <el-form-item label="关键字">
            <el-input
              v-model="where.keyWord"
              placeholder="请输入商家名称/姓名/手机号/门店名称"
              clearable
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" plain icon="el-icon-search" @click="showDetail()"
              >条件筛选</el-button
            >
          </el-form-item>
        </el-form>

        <el-table :data="list" @selection-change="handleSelectionChange">
          <el-table-column label="ID" width="150">
            <template slot-scope="scope">
              <el-radio v-model="checked" :label="scope.row.shopId"></el-radio>
            </template>
          </el-table-column>
          <el-table-column
            property="shopName"
            label="商户名称"
            width="200"
          ></el-table-column>
          <el-table-column
            property="responsiblePhone"
            label="商家手机号"
          ></el-table-column>
          <el-table-column
            property="merchantName"
            label="门店名称"
          ></el-table-column>
        </el-table>
        <div class="admin_table_main_pagination">
          <el-pagination
            @current-change="currentChange"
            @size-change="handleSizeChange"
            background
            :total="totalData"
            :page-size="pageSize"
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            layout="sizes, prev, pager, next, total, jumper"
          ></el-pagination>
        </div>
      </el-timeline>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleSendClose">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    row: Object,
  },
  data() {
    return {
      dialogSendVisible: false,
      logisticsInfos: "",
      list: [],
      checked: null,
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      selectId: "",
      where: {
        limit: 10,
        page: 1,
        keyWord: "",
      },
    };
  },
  methods: {
    handleSendClose() {
      this.dialogSendVisible = false;
      this.$emit("dialog", false);
    },
    handleSelectionChange(e) {
      this.list.forEach((item) => {
        // 排他,每次选择时把其他选项都清除
        if (item.shopId !== e.shopId) {
          item.checked = false;
        } else {
          this.selectId = e;
        }
      });
    },
    currentChange(e) {
      this.currentPage = e;
      this.listData();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.listData();
    },
    showDetail() {
      this.$postFormData(this.$api.payCodeShopList, this.where).then((res) => {
        if (res.code == 1) {
          this.list = res.data.data;
        } else {
          this.$message({
            type: "error",
            message: res.msg || res.message,
          });
        }
      });
    },
    submit() {
      if (!this.checked) {
        this.$message({
          type: "error",
          message: "请选择门店",
        });
        return false;
      }
      let data = {
        payQrcodeNo: this.row.payQrcodeNo,
        shopId: this.checked,
      };
      this.$post(this.$api.payCodeShopBindCode, data).then((res) => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: res.msg || res.message,
          });
          this.dialogSendVisible = false;
          this.$parent.listData();
        } else {
          this.$message({
            type: "error",
            message: res.msg || res.message,
          });
        }
      });
    },
  },
  created() {
    this.dialogSendVisible = true;
    this.showDetail();
  },
};
</script>
